<template>
  <va-card>
    <div class="row align--center">
      <div class="flex xs12 md6" v-if="canAddGenre">
        <add-genre-modal />
      </div>
      <div class="flex xs12 md3 offset--md3" v-if="canListGenre">
        <va-input
          v-model="term"
          :placeholder="$t('tables.searchByName')"
          @keydown="searchGenre"
          removable
        >
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <div v-if="canListGenre">
      <loader v-if="isLoading" />
      <data-table
        :fields="fields"
        :data="genres"
        :per-page="pagination.limit"
        :total-pages="pagination.pages"
        :currentPage="pagination.currentPage"
        :api-mode="true"
        @page-selected="loadGenres"
        v-else
      >
        <template slot="thumbnail" slot-scope="props">
          <genre-thumbnail
            :genreId="props.rowData.id"
          />
        </template>
        <template slot="genreGroup" slot-scope="props">
          <genre-group
            :genreGroup="props.rowData.groupType"
            :genreId="props.rowData.id"
            @updateGenre="updateGenreArray"
            v-if="canUpdateGenre"
          />
        </template>
        <template slot="actions" slot-scope="props">
          <genre-actions
            :genreName="props.rowData.name"
            :genreId="props.rowData.id"
            :canEdit="canEditGenre"
            :canDelete="canDeleteGenre"
            @updateGenre="updateGenreArray"
            @deleteGenre="deleteFromGenreArray"
          />
        </template>
      </data-table>
    </div>
    <access-denied v-else />
  </va-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { fetchGenres } from '../../apollo/api/genres';
import { GENRES_TABLE_FIELD } from '../../constants/tableFields';
import { isAllowed } from '../../mixins/utils';
import DataTable from '../../components/DataTable';
import GenreGroup from './GenreGroup';
import GenreThumbnail from './GenreThumbnail';
import GenreActions from './GenreActions';
import AddGenreModal from './AddGenreModal';
import Loader from '../../components/Loader';

export default {
  components: {
    DataTable,
    GenreGroup,
    GenreThumbnail,
    GenreActions,
    AddGenreModal,
    Loader,
  },
  data() {
    return {
      term: '',
      genres: [],
      pagination: {
        limit: 50,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
    };
  },
  computed: {
    fields() {
      return GENRES_TABLE_FIELD;
    },
    ...mapGetters(['isLoading', 'ACCESS_CODES', 'RESOURCE_CODES']),
    canListGenre() {
      return isAllowed([this.RESOURCE_CODES.GENRE([this.ACCESS_CODES.LIST])]);
    },
    canAddGenre() {
      return isAllowed([this.RESOURCE_CODES.GENRE([this.ACCESS_CODES.CREATE])]);
    },
    canUpdateGenre() {
      return isAllowed([
        this.RESOURCE_CODES.READER([this.ACCESS_CODES.UPDATE]),
      ]);
    },
    canEditGenre() {
      return isAllowed([
        this.RESOURCE_CODES.GENRE([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.EDIT,
        ]),
      ]);
    },
    canDeleteGenre() {
      return isAllowed([
        this.RESOURCE_CODES.GENRE([
          this.ACCESS_CODES.LIST,
          this.ACCESS_CODES.DELETE,
        ]),
      ]);
    },
  },
  watch: {
    term: function(newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length === 0) {
        this.loadGenres();
      }
    },
  },
  async mounted() {
    await this.loadGenres(1);
  },
  methods: {
    ...mapMutations(['setLoading']),
    async loadGenres(page = 1) {
      this.setLoading(true);
      try {
        const { genresList } = await fetchGenres(
          this.term,
          '',
          this.pagination.limit,
          page,
        );
        this.genres = genresList.genres;
        this.pagination = {
          ...this.pagination,
          currentPage: genresList.currentPage,
          pages: genresList.pages,
          total: genresList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    async searchGenre(e) {
      if (e.key === 'Enter') {
        await this.loadGenres();
      }
    },
    updateGenreArray(genre) {
      const newGenres = this.genres.map(g => {
        if (g.id === genre.id) {
          return { ...g, ...genre };
        }
        return g;
      });
      this.genres = newGenres;
    },
    deleteFromGenreArray(genre) {
      const newGenres = this.genres.filter(g => g.id !== genre.id);
      this.genres = newGenres;
    },
  },
};
</script>
