<template>
  <div>
    <a @click="editGenre" v-if="canEdit">
      <va-icon
        name="fa fa-pencil-square-o"
        :size="21"
        class="action-icon pointer"
      />
    </a>
    <va-modal
      v-model="showModal"
      size="small"
      title="Update Genre"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="updateGenreName"
      @cancel="resetForm"
    >
      <va-input v-model="name" placeholder="Genre name" removable />
    </va-modal>

    <a @click="deleteGenreShowModal = true" v-if="canDelete">
      <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
    </a>
    <va-modal
      v-model="deleteGenreShowModal"
      size="small"
      title="Delete Genre"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteGenreName(false)"
      @cancel="resetForm"
    >
      Are you sure you want to delete genre
    </va-modal>

    <a @click="forceDeleteGenreShowModal = true" v-if="canDelete">
      <va-icon
        name="fa fa-trash-o"
        :size="21"
        class="action-icon pointer force-action-item"
      />
    </a>
    <va-modal
      v-model="forceDeleteGenreShowModal"
      size="small"
      title="Delete Genre"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteGenreName(true)"
      @cancel="resetForm"
    >
      Are you sure you want to <b>FORCE</b> delete genre, it will delete genres
      from everywhere
    </va-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import {
  updateGenre,
  deleteGenre,
  forceDeleteGenre,
} from '../../apollo/api/genres';

export default {
  name: 'genre-actions',
  props: {
    genreName: String,
    genreId: Number,
    canEdit: Boolean,
    canDelete: Boolean,
  },
  data() {
    return {
      name: { ...this.genreName },
      showModal: false,
      deleteGenreShowModal: false,
      forceDeleteGenreShowModal: false,
    };
  },
  methods: {
    ...mapMutations(['setBackgroundLoading']),
    resetForm() {
      this.name = this.genreName;
    },
    editGenre() {
      this.resetForm();
      this.showModal = true;
    },
    async updateGenreName() {
      this.setBackgroundLoading(true);
      try {
        await updateGenre(this.genreId, this.name);
        this.$emit('updateGenre', { id: this.genreId, name: this.name });
        this.showToast('Genre updated successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.resetForm();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setBackgroundLoading(false);
    },
    async deleteGenreName(forceDeleteFlag = false) {
      this.setBackgroundLoading(true);
      try {
        if (forceDeleteFlag) {
          await forceDeleteGenre(this.genreId);
        } else {
          await deleteGenre(this.genreId);
        }
        this.showToast('Genre deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.resetForm();
        this.$emit('deleteGenre', { id: this.genreId });
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 2000,
          fullWidth: false,
        });
      }
      this.setBackgroundLoading(false);
    },
  },
};
</script>
